























































































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import Http from "@/Http"
import Rules from "@/Rules"
import {Content, Source, VData} from "@/Models"

@Component({
  components: {TableBasedView}
})
export default class Sources extends Vue {

  currentDate = new Date()
  currentTs = this.currentDate.getFullYear() * 10000 + (this.currentDate.getMonth() + 1) * 100 + this.currentDate.getDate()

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'custom1', width: '25%'},
    {text: 'URL', align: 'start', sortable: false, value: 'url', width: '50%'},
    {text: 'Tags', align: 'start', sortable: false, value: 'contentTags', width: '25%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  date1 = false
  date2 = false
  defaultItem = new Source()
  rules = new Rules()
  http = new Http()
  loading = true
  loadingError = false
  vdata: VData = new VData()

  get apiUrl(): string {
    return "sources/" + this.$route.params.sourceTagId
  }

  isValid(item: Content): boolean {
    let from = parseInt(item.validityFrom.replaceAll("-", ""))
    let to = parseInt(item.validityTo.replaceAll("-", ""))
    return (from < this.currentTs && to > this.currentTs)
  }

  created() {
    this.loadData()
  }

  loadData() {
    this.loading = true
    this.loadingError = false
    this.http.apiGet<VData>(
        "data",
        (value) => {
          this.vdata = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  add1Y(validity: string): string {
    const parts: string[] = validity.split("-");
    return (parseInt(parts[0]) + 1) + "-" + parts[1] + "-" + parts[2];
  }

}
