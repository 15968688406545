










































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Rules from "@/Rules"
import TableBasedView from "@/components/TableBasedView.vue"
import {Instruction} from "@/Models"

@Component({
  components: {TableBasedView}
})
export default class UtmUrls extends Vue {

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'name', width: '20%'},
    {text: 'Pattern', align: 'start', sortable: false, value: 'custom1', width: '40%'},
    {text: 'Post', align: 'start', sortable: false, value: 'custom2', width: '40%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  defaultItem = new Instruction()
  rules = new Rules()

  openUrl(url: string) {
    window.open(url, '_blank');
  }

}
