import Vuex from 'vuex'
import {createModule, createProxy, mutation, action, extractVuexModule} from "vuex-class-component"
import {Vue} from "vue-property-decorator"
import Cookies, {CookieAttributes} from 'js-cookie'
import {OrgResponse} from "@/Models";


Vue.use(Vuex)

const VuexModule = createModule({
    namespaced: "app",
    strict: false
})


export class StoreSnack {
    message: string
    color: string
    timeout: number = 2000

    constructor(message: string, color: string, timeout: number = 2000) {
        this.message = message
        this.color = color
        this.timeout = timeout
    }
}


export class AppStore extends VuexModule {


    private hideMenus = false
    private userToken: string | undefined = undefined
    private snack: StoreSnack | undefined = undefined

    get getUserToken(): string | undefined {
        if (this.userToken == undefined) {
            let token = Cookies.get('user_token')
            if (token != undefined) {
                this.setUserToken(token)
            }
        }
        return this.userToken
    }

    get isLogged() {
        return this.getUserToken != undefined
    }

    get showMenus() {
        return !this.hideMenus
    }

    @mutation
    toggleMenus(value: boolean) {
        this.hideMenus = !value
    }

    @mutation
    setUserToken(newToken: string) {
        let attr: CookieAttributes = {
            sameSite: "lax",
            expires: 365
        }
        Cookies.set('user_token', newToken, attr)
        this.userToken = newToken
    }

    @mutation
    sendSnack(snack: StoreSnack) {
        this.snack = snack
    }

    @mutation
    clearToken() {
        this.userToken = undefined
        Cookies.remove('user_token')
    }


    /* ORGANIZATIONS */

    private orgId = -1
    private loadedOrgId = -1
    private orgResponse: OrgResponse = new OrgResponse()

    get getIsOrgAdmin(): boolean {
        return this.orgResponse.role == 0
    }

    get getIsOrgMember(): boolean {
        return this.orgResponse.role >= 0
    }

    get getOrgName(): string {
        return this.orgResponse.name
    }

    get getOrgId(): string {
        return this.orgId.toString()
    }

    get getLoadedOrgId(): string {
        return this.loadedOrgId.toString()
    }

    @mutation
    setOrgId(orgId: string) {
        this.orgId = Number(orgId)
    }

    @mutation
    setLoadedOrgId(orgId: string) {
        this.loadedOrgId = Number(orgId)
    }

    @mutation
    setOrgResponse(value: OrgResponse) {
        this.orgResponse = value
    }

}


export const store = new Vuex.Store({
    modules: {
        ...extractVuexModule(AppStore)
    }
})


export const vxm = {
    app: createProxy(store, AppStore),
}