






















































import Vue from 'vue'
import Component from 'vue-class-component'
import {StoreSnack, vxm} from '@/Store'
import GoogleButton from '@/components/GoogleButton.vue'
import {LoginResponse} from "@/Models";
import Http from "@/Http"

@Component({
  components: {GoogleButton}
})
export default class Login extends Vue {

  loadingLogin = false
  valid = true
  userName = ""
  password = ""
  errorSummary = ""
  http = new Http()

  get showError() {
    return this.errorSummary.length > 0
  }

  submit() {
    let grecaptcha = (window as any).grecaptcha
    grecaptcha.ready(() => {
      grecaptcha.execute('6Lfu5eIZAAAAAJsUSNu8itDOwWz0b57AfQyqhLjv', {action: 'login'}).then((token: string) => {
        this.loadingLogin = true
        let that = this

        this.http.globalPost<LoginResponse>(
            "auth/login",
            {
              username: this.userName,
              password: this.password,
              recaptcha: token
            },
            (json: LoginResponse) => {
              if (json.result) {
                vxm.app.setUserToken(json.token)
              } else {
                vxm.app.sendSnack(new StoreSnack(json.message, "error", 4000))
              }
            },
            error => {
              if (error) {
                vxm.app.sendSnack(new StoreSnack("Communication with the server failed!", "error", 4000))
              }
              this.$nextTick(() => {
                that.loadingLogin = false
              })
            }
        )

      });
    });
  }

}
