import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Login from '../views/Login.vue'
import UtmUrls from "@/views/UtmUrls.vue";
import TagsContent from "@/views/TagsContent.vue";
import TagsSource from "@/views/TagsSource.vue";
import TagsHash from "@/views/TagsHash.vue";
import Instructions from "@/views/Instructions.vue";
import Sources from "@/views/Sources.vue";
import Contents from '@/views/Contents.vue';
import Placeholders from "@/views/Placeholders.vue";
import Variants from "@/views/Variants.vue";
import Dashboard from "@/views/Dashboard.vue";
import Users from "@/views/Users.vue";
import Profile from "@/views/Profile.vue";
import Tasks from "@/views/Tasks.vue";
import {vxm} from "@/Store";
import UtmMedia from "@/views/UtmMedia.vue";
import UtmCampaigns from "@/views/UtmCampaign.vue";
import Placements from "@/views/Placements.vue";
import Http from "@/Http";
import {OrgResponse} from "@/Models";
import Checks from "@/views/Checks.vue";
import TagsSingle from "@/views/TagsSingle.vue";
import SingleTasks from "@/views/SingleTasks.vue";
import SingleTemplates from "@/views/SingleTemplates.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

    {
        path: '/login',
        component: Login,
    },
    {
        path: '/',
        component: Dashboard,
    },
    {
        path: '/o/:orgId/tasks',
        component: Tasks,
        meta: {
            type: "member"
        }
    },
    {
        path: '/o/:orgId/profile',
        component: Profile,
        meta: {
            type: "member"
        }
    },
    {
        path: "/o/:orgId/source-tags",
        component: TagsSource,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/content-tags",
        component: TagsContent,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/single-tags",
        component: TagsSingle,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/single-tasks",
        component: SingleTasks,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/single-templates",
        component: SingleTemplates,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/hash-tags",
        component: TagsHash,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/placeholders",
        component: Placeholders,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/instructions",
        component: Instructions,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/utm-urls",
        component: UtmUrls,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/utm-media",
        component: UtmMedia,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/utm-campaigns",
        component: UtmCampaigns,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/locations",
        component: Placements,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/sources/:sourceTagId",
        component: Sources,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/contents",
        component: Contents,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/contents/:contentId",
        component: Variants,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/checks",
        component: Checks,
        meta: {
            type: "admin"
        }
    },
    {
        path: "/o/:orgId/users",
        component: Users,
        meta: {
            type: "admin"
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (to.params.orgId != undefined) {
        // If the URL contains organization name, we must first fetch organization data if
        // they are not yet available.
        vxm.app.setOrgId(to.params.orgId)
        if (to.params.orgId == "-1" || vxm.app.getLoadedOrgId == "-1" || to.params.orgId != vxm.app.getLoadedOrgId || vxm.app.getOrgName == "") {
            new Http().apiGet<OrgResponse>(
                "",
                (resp) => {
                    vxm.app.setOrgResponse(resp)
                    vxm.app.setLoadedOrgId(to.params.orgId)
                    vxm.app.toggleMenus(true)
                    if (!vxm.app.getIsOrgAdmin && to && to.meta && to.meta.type == "admin") {
                        next("/o/" + to.params.orgId + "/tasks")
                    } else {
                        next()
                    }
                },
                error => {
                    if (error) {
                        // Authorization error is process in Http class, so only set the correct values.
                        vxm.app.setOrgId("-1")
                        vxm.app.setLoadedOrgId("-1")
                    }
                }
            )
        } else {
            if (!vxm.app.getIsOrgAdmin && to && to.meta && to.meta.type == "admin") {
                next("/o/" + to.params.orgId + "/tasks")
            } else {
                next()
            }
        }
    } else {
        // If the URL doesn't contain organization name, just render it fullscreen.
        vxm.app.setOrgId("-1")
        vxm.app.setLoadedOrgId("-1")
        vxm.app.toggleMenus(false)
        next()
    }
})


export default router
