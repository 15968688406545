

















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import Http from "@/Http"
import Rules from "@/Rules"
import {Tokens, VData, User, ResultResponse} from "@/Models"
import {vxm, StoreSnack} from "@/Store";


@Component({
  components: {TableBasedView}
})
export default class Users extends Vue {

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'custom1', width: '30%'},
    {text: 'Tags', align: 'start', sortable: false, value: 'custom2', width: '50%'},
    {text: 'Batch', align: 'center', sortable: false, value: 'custom3', width: '10%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  defaultItem = new User()
  inviteDialog = false
  rules = new Rules()
  http = new Http()
  requests = 0
  loading = true
  loadingError = false
  vdata: VData = new VData()
  tokens: Tokens = new Tokens()

  created() {
    this.loadData()
  }

  loadData() {
    this.requests = 2
    this.loading = true
    this.loadingError = false

    this.http.apiGet<VData>(
        "data",
        (value) => {
          this.vdata = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )

    this.http.apiGet<Tokens>(
        "tokens",
        (value) => {
          this.tokens = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )

  }

  finishLoading(wasError: boolean) {
    this.requests--
    if (this.requests == 0) {
      this.$nextTick(() => {
        this.loading = false
        this.loadingError = wasError
      })
    }
  }

  clearBatch(id: string) {
    this.http.apiGet<ResultResponse>(
        "admin/clear-batch/" + id,
        (value) => {
          if (value.result) {
            vxm.app.sendSnack(new StoreSnack("Cleared!", "success"))
          } else {
            vxm.app.sendSnack(new StoreSnack("Operation failed!", "error"))
          }
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  copyToClipboard(message: string) {
    navigator.clipboard.writeText(message).then(() => {
      vxm.app.sendSnack(new StoreSnack("Copied to clipboard.", "success"))
    }).catch(e => {
      vxm.app.sendSnack(new StoreSnack("Cannot copy text to clipboard. Please copy it manually.", "error"))
    });
  }

}
