import 'whatwg-fetch'
import {vxm, StoreSnack} from "@/Store"

export default class Http {

    static baseUrl = process.env.VUE_APP_BASE_URL

    static unknownError = "Unknown error occurred!"
    static authorizationFailed = "You are not authorized to perform the operation."

    printError = true

    private process<T>(
        response: any,
        onJson: (json: T) => void,
        onFinished: (error: boolean) => void
    ) {

        if (response.status == 401) {
            vxm.app.sendSnack(new StoreSnack(Http.authorizationFailed, "error", 4000))
            vxm.app.clearToken()
            window.location.href = "/login"
            onFinished(true)
            return
        }

        if (response.status == 400) {
            response.text().then((value: string) => {
                vxm.app.sendSnack(new StoreSnack(value, "error", 4000))
            }).catch((error: any) => {
                if (this.printError) console.log(error)
                vxm.app.sendSnack(new StoreSnack(Http.unknownError, "error", 4000))
            })
            onFinished(true)
            return
        }

        if (response.status == 200) {
            response.json().then((value: T) => {
                onJson(value)
                onFinished(false)
            }).catch((error: any) => {
                if (this.printError) console.log(error)
                vxm.app.sendSnack(new StoreSnack(Http.unknownError, "error", 4000))
                onFinished(true)
            })
        } else {
            vxm.app.sendSnack(new StoreSnack(Http.unknownError, "error", 4000))
            onFinished(true)
        }
    }


    private request<T>(
        url: string,
        method: string,
        data: any | undefined,
        headers: any,
        onJson: (json: T) => void,
        onFinished: (error: boolean) => void
    ) {
        if (!url.startsWith("/")) url = "/" + url
        fetch(Http.baseUrl + url, {
            method: method,
            headers: headers,
            body: data == undefined ? data : JSON.stringify(data)
        }).then(response => {
            this.process(response, onJson, onFinished)
        }).catch(error => {
            if (this.printError) console.log(error)
            onFinished(true)
        })
    }


    apiGet<T>(url: string, onJson: (json: T) => void, onFinished: (error: boolean) => void) {
        this.request<T>("api/org/" + vxm.app.getOrgId + (url.startsWith("/") ? url : "/" + url), 'GET', undefined, {'Authorization': 'Bearer ' + vxm.app.getUserToken}, onJson, onFinished)
    }

    apiDelete<T>(url: string, onJson: (json: T) => void, onFinished: (error: boolean) => void) {
        this.request<T>("api/org/" + vxm.app.getOrgId + (url.startsWith("/") ? url : "/" + url), 'DELETE', undefined, {'Authorization': 'Bearer ' + vxm.app.getUserToken}, onJson, onFinished)
    }

    apiPost<T>(url: string, data: any, onJson: (json: T) => void, onFinished: (error: boolean) => void) {
        this.request<T>("api/org/" + vxm.app.getOrgId + (url.startsWith("/") ? url : "/" + url), 'POST', data, {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + vxm.app.getUserToken}, onJson, onFinished)
    }

    userGet<T>(url: string, onJson: (json: T) => void, onFinished: (error: boolean) => void) {
        this.request<T>(url, 'GET', undefined, {'Authorization': 'Bearer ' + vxm.app.getUserToken}, onJson, onFinished)
    }

    userDelete<T>(url: string, onJson: (json: T) => void, onFinished: (error: boolean) => void) {
        this.request<T>(url, 'DELETE', undefined, {'Authorization': 'Bearer ' + vxm.app.getUserToken}, onJson, onFinished)
    }

    userPost<T>(url: string, data: any, onJson: (json: T) => void, onFinished: (error: boolean) => void) {
        this.request<T>(url, 'POST', data, {'Authorization': 'Bearer ' + vxm.app.getUserToken}, onJson, onFinished)
    }

    globalPost<T>(url: string, data: any, onJson: (json: T) => void, onFinished: (error: boolean) => void) {
        this.request<T>(url, 'POST', data, {'Content-Type': 'application/json'}, onJson, onFinished)
    }

}