






















































































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import Http from "@/Http"
import {Content, SingleTask, VData} from "@/Models"
import Rules from "@/Rules"
import {vxm} from "@/Store"

@Component({components: {TableBasedView}})
export default class SingleTasks extends Vue {

  currentDate = new Date()
  currentTs = this.currentDate.getFullYear() * 10000 + (this.currentDate.getMonth() + 1) * 100 + this.currentDate.getDate()

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'custom2', width: '60%'},
    {text: 'Tags', align: 'start', sortable: false, value: 'custom1', width: '35%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  template = -1
  date1 = false
  date2 = false
  store = vxm.app
  defaultItem = new SingleTask()
  rules = new Rules()
  http = new Http()
  loading = true
  loadingError = false
  vdata: VData = new VData()

  isValid(item: Content): boolean {
    let to = parseInt(item.validityTo.replaceAll("-", ""))
    return (to > this.currentTs)
  }

  created() {
    this.loadData()
  }

  loadData() {
    this.loading = true
    this.loadingError = false
    this.http.apiGet<VData>(
        "data",
        (value) => {
          this.vdata = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  add1Y(validity: string): string {
    const parts: string[] = validity.split("-")
    let month = parseInt(parts[1]) + 1
    let year = parseInt(parts[0])
    let day = parseInt(parts[2])
    if (month > 12) {
      year++;
      month = 1;
    }
    if (day > 28) day = 28
    return (year.toString() + "-" + month.toString().padStart(2, "0") + "-" + day.toString().padStart(2, "0"))
  }

  beforeDialog() {
    this.template = -1
  }

  useTemplate(editedItem: SingleTask) {
    if (this.template === -1) return
    const tpl = this.vdata.singleTemplates.find(item => item.id == this.template)
    if (tpl == undefined) return
    editedItem.name = "[" + new Date().toISOString().substr(0, 10) + "] " + tpl.name
    editedItem.action = tpl.action
    editedItem.actionName = tpl.actionName
    editedItem.text = tpl.text
    editedItem.singleTags = tpl.singleTags
  }

  openTemplate() {
    this.$router.push("/o/" + this.store.getOrgId + "/single-templates")
  }

}
