import {SourceTag} from "@/Models";

export default class Rules {

    selected = [(v: string) => (v != undefined && v != "" && v != "-1") ? true : "Please select."]

    r32 = [(v: string) => Rules.itemStringError(v, 32, false)]
    r250 = [(v: string) => Rules.itemStringError(v, 250, false)]
    r1000 = [(v: string) => Rules.itemStringError(v, 1000, false)]
    r4000 = [(v: string) => Rules.itemStringError(v, 4000, false)]

    r32Empty = [(v: string) => Rules.itemStringError(v, 32, true)]
    r250Empty = [(v: string) => Rules.itemStringError(v, 250, true)]
    r1000Empty = [(v: string) => Rules.itemStringError(v, 1000, true)]
    r4000Empty = [(v: string) => Rules.itemStringError(v, 4000, true)]

    n20 = [(v: string) => Rules.itemNumberError(v, 20)]
    n30Zero = [(v: string) => Rules.itemNumberError(v, 30, 0)]
    n100Zero = [(v: string) => Rules.itemNumberError(v, 100, 0)]
    n30 = [(v: string) => Rules.itemNumberError(v, 30)]
    n100 = [(v: string) => Rules.itemNumberError(v, 100)]
    n1000 = [(v: string) => Rules.itemNumberError(v, 10000)]

    private static itemStringError(input: string | undefined, max: number, canBeEmpty: boolean): string | boolean {
        if (input == undefined) return true
        if (!canBeEmpty && input.length == 0) return "Cannot be empty."
        if (input.length > max) return "Cannot be longer than " + max + " chars."
        return true
    }

    private static itemNumberError(input: string | undefined, max: number, min: number = 1): string | boolean {
        if (input == undefined) return true
        let parsed = Number(input)
        if (isNaN(parsed)) return "Must be number."
        if (parsed < min || parsed > max) return "Must be number between " + min + " and " + max + "."
        return true
    }

}