































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import Rules from '@/Rules'
import Http from '@/Http'
import {StoreSnack, vxm} from "@/Store"
import {OrgList} from "@/Models";


interface Response {
  add: boolean,
  result: boolean,
  id: string,
  name: string,
  role: number
}

interface DeleteResponse {
  result: boolean
}


@Component
export default class Dashboard extends Vue {

  rules = new Rules()
  newDialog = false
  joinDialog = false
  deleteDialog = false
  deleteId = -1
  editName = ""
  editUrl = ""
  editId = -1
  editJoin = ""
  organizations: OrgList = new OrgList()
  http = new Http()
  loading = false
  loadingError = false

  created() {
    this.loadData()
  }

  loadData() {
    this.loading = true
    this.loadingError = false
    this.http.userGet<OrgList>(
        "api/org",
        (value) => {
          this.organizations = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }

  private static canProcess(ref: any): boolean {
    let canProceed = true
    if (ref != undefined && !ref.validate()) canProceed = false
    return canProceed
  }


  changeName(item: any) {
    this.editName = item.name
    this.editUrl = JSON.parse(item.data).url
    this.editId = item.id
    this.newDialog = true
  }


  saveJoin(ref: any) {
    if (Dashboard.canProcess(ref)) {
      this.http.userPost<Response>(
          "api/join",
          {token: this.editJoin},
          (response: Response) => {
            if (response.result) {
              this.loadData()
              this.closeJoin(ref)
            } else {
              vxm.app.sendSnack(new StoreSnack("Invalid code!", "error"))
            }
          },
          (error) => {
          }
      )
    }
  }

  saveNew(ref: any) {
    if (Dashboard.canProcess(ref)) {
      this.http.userPost<Response>(
          "api/org",
          {id: this.editId, name: this.editName, data: JSON.stringify({url: this.editUrl})},
          (response: Response) => {
            if (response.result) {
              this.loadData()
              this.closeNew(ref)
            }
          },
          (error) => {
          }
      )
    }
  }

  closeJoin(ref: any) {
    this.joinDialog = false
    this.editJoin = ""
    if (ref != undefined) this.$nextTick(() => ref.resetValidation())
  }

  closeNew(ref: any) {
    this.newDialog = false
    this.editName = ""
    this.editId = -1
    this.editUrl = ""
    if (ref != undefined) this.$nextTick(() => ref.resetValidation())
  }

  deleteRole(item: any) {
    this.deleteId = item.id
    this.deleteDialog = true
  }

  closeDelete() {
    this.deleteDialog = false
  }

  saveDelete() {
    if (this.deleteId != -1) {
      this.http.userDelete<DeleteResponse>(
          "api/org/" + this.deleteId,
          (response) => {
            if (response.result) {
              let index = this.organizations.list.findIndex(mt => mt.id == this.deleteId)
              this.organizations.list.splice(index, 1)
              this.deleteId = -1
            }
          },
          (error) => {
          }
      )
    }
    this.deleteDialog = false
  }

  logout() {
    vxm.app.clearToken()
    this.$router.replace("/login")
  }

}
