








































































import Vue from 'vue'
import Component from 'vue-class-component'
import Http from "@/Http";
import {BatchData, UserData, VData, ResultResponse, BatchItem} from "@/Models";
import {StoreSnack, vxm} from "@/Store";

@Component
export default class Tasks extends Vue {

  loadingBatch = false
  loadingGenerate = false
  loadingError = false
  confirmDialog = false
  confirmAction = ""
  confirmTask: BatchItem | undefined = undefined
  tasks = []
  checks = []
  http = new Http()
  data: BatchData = new BatchData()

  created() {
    this.loadData()
  }


  loadData() {
    this.loadingError = false
    this.loadingBatch = true
    this.http.apiGet<BatchData>(
        "batch",
        (value) => {
          this.data = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }


  generateBatch() {
    this.loadingError = false
    this.loadingGenerate = true
    this.http.apiGet<ResultResponse>(
        "batch/generate",
        (value) => {
          if (value.result) {
            setTimeout(() => {
              this.loadData()
            }, 500)
          } else {
            setTimeout(() => {
              this.finishLoading(true)
            }, 500)
          }
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  nonEmptyActionName(task: BatchItem) {
    if (task.actionName.length === 0) return "Perform action"
    return task.actionName
  }

  bunchAction(task: BatchItem) {
    this.copyToClipboard(task.post)
    this.changeTask(task, "accept")
    setTimeout(() => {
      this.launchAction(task)
    }, 250)
  }

  launchAction(task: BatchItem) {
    window.open(task.action, '_blank');
  }

  acceptTask(task: BatchItem) {
    this.confirmAction = "accept"
    this.confirmTask = task
    this.confirmDialog = true
  }

  rejectTask(task: BatchItem) {
    this.confirmAction = "reject"
    this.confirmTask = task
    this.confirmDialog = true
  }

  cancelChange() {
    this.confirmAction = ""
    this.confirmTask = undefined
    this.confirmDialog = false
  }

  confirmChange() {
    this.confirmDialog = false
    if (this.confirmTask != undefined) {
      this.changeTask(this.confirmTask as BatchItem, this.confirmAction)
    }
    this.confirmAction = ""
    this.confirmTask = undefined
  }

  changeTask(task: BatchItem, type: string) {
    this.http.apiPost<ResultResponse>(
        "batch",
        {
          "action": type,
          "type": task.type,
          "id": task.id
        },
        (value) => {
          if (value.result) {
            if (type == "accept") task.accepted = true
            if (type == "reject") task.rejected = true
          }
        },
        (wasError) => {
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loadingError = wasError
      this.loadingGenerate = false
      this.loadingBatch = false
    })
  }

  copyToClipboard(message: string) {
    navigator.clipboard.writeText(message).then(() => {
      vxm.app.sendSnack(new StoreSnack("Copied to clipboard.", "success"))
    }).catch(e => {
      vxm.app.sendSnack(new StoreSnack("Cannot copy text to clipboard. Please copy it manually.", "error"))
    });
  }

}
