


















































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import {SourceTag} from "@/Models"
import Rules from "@/Rules"
import {vxm} from "@/Store"

@Component({
  components: {TableBasedView}
})
export default class TagsSource extends Vue {

  store = vxm.app

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'name', width: '44%'},
    {text: 'Batch', align: 'center', sortable: false, value: 'maxBatch', width: '5%'},
    {text: 'User', align: 'center', sortable: false, value: 'maxUser', width: '5%'},
    {text: 'Source', align: 'center', sortable: false, value: 'maxSource', width: '5%'},
    {text: 'WaitUser', align: 'center', sortable: false, value: 'waitUser', width: '7%'},
    {text: 'WaitAll', align: 'center', sortable: false, value: 'waitAll', width: '7%'},
    {text: 'WaitGroup', align: 'center', sortable: false, value: 'waitTag', width: '7%'},
    {text: 'Private', align: 'center', sortable: false, value: 'private', width: '10%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  defaultItem = new SourceTag()
  rules = new Rules()

}
