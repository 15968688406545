



















































































import {Component, Vue} from 'vue-property-decorator'
import {vxm} from "@/Store"
import Snackbar from "@/components/Snackbar.vue";

@Component({
  components: {
    Snackbar
  }
})
export default class App extends Vue {

  drawer = false
  store = vxm.app

  logout() {
    vxm.app.clearToken()
    this.$router.replace("/login")
  }

  get getItems(): any {
    return [

      {
        admin: false,
        items: [
          {
            color: "primary",
            icon: "mdi-apps-box",
            href: "/o/" + this.store.getOrgId + "/tasks",
            title: "Tasks"
          },
          {
            color: "primary",
            icon: "mdi-cog-box",
            href: "/o/" + this.store.getOrgId + "/profile",
            title: "Profile"
          }
        ]
      },

      {
        admin: true,
        items: [
          {
            color: "primary",
            icon: "mdi-text-box",
            href: "/o/" + this.store.getOrgId + "/contents",
            title: "Content"
          },
          {
            color: "primary",
            icon: "mdi-rss-box",
            href: "/o/" + this.store.getOrgId + "/source-tags",
            title: "Sources"
          }
        ]
      },

      {
        admin: true,
        items: [
          {
            color: "primary",
            icon: "mdi-checkbox-marked",
            href: "/o/" + this.store.getOrgId + "/single-tasks",
            title: "Single Tasks"
          },
          {
            color: "primary",
            icon: "mdi-email-box",
            href: "/o/" + this.store.getOrgId + "/checks",
            title: "Regular Checks"
          }
        ]
      },

      {
        admin: true,
        items: [
          {
            color: "primary",
            icon: "mdi-account-box",
            href: "/o/" + this.store.getOrgId + "/users",
            title: "Users"
          }
        ]
      },

      {
        admin: true,
        items: [
          {
            color: "primary",
            icon: "mdi-heart-box",
            href: "/o/" + this.store.getOrgId + "/content-tags",
            title: "Content Tags"
          },
          {
            color: "primary",
            icon: "mdi-star-box",
            href: "/o/" + this.store.getOrgId + "/single-tags",
            title: "Single Tags"
          },
          {
            color: "primary",
            icon: "mdi-pound-box",
            href: "/o/" + this.store.getOrgId + "/hash-tags",
            title: "Hash Tags"
          },
          /*
          {
            color: "primary",
            icon: "mdi-file-table-box",
            href: "/o/" + this.store.getOrgId + "/single-templates",
            title: "Single Templates"
          },
          */
          {
            color: "primary",
            icon: "mdi-code-braces-box",
            href: "/o/" + this.store.getOrgId + "/placeholders",
            title: "Placeholders"
          },
          {
            color: "primary",
            icon: "mdi-help-box",
            href: "/o/" + this.store.getOrgId + "/instructions",
            title: "Instructions"
          },
          {
            color: "primary",
            icon: "mdi-link-box-variant",
            href: "/o/" + this.store.getOrgId + "/utm-urls",
            title: "UTM Urls"
          },
          {
            color: "primary",
            icon: "mdi-web-box",
            href: "/o/" + this.store.getOrgId + "/utm-media",
            title: "UTM Media"
          },
          {
            color: "primary",
            icon: "mdi-poll-box",
            href: "/o/" + this.store.getOrgId + "/utm-campaigns",
            title: "UTM Campaigns"
          }
        ]
      }

    ]
  }

}
