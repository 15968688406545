

































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import {HashTag} from "@/Models"
import Rules from "@/Rules"

@Component({
  components: {TableBasedView}
})
export default class TagsHash extends Vue {

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'name', width: '25%'},
    {text: 'Tags', align: 'start', sortable: false, value: 'value', width: '75%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  defaultItem = new HashTag()
  rules = new Rules()

}
