






import Component from "vue-class-component";
import Vue from "vue";
import {vxm, StoreSnack} from "@/Store"
import Http from "@/Http"
import {LoginResponse} from "@/Models"


@Component
export default class GoogleButton extends Vue {

  loading = false
  http = new Http()

  mounted() {
    let gapi = (window as any).gapi
    gapi.load('auth2', () => {
      let auth2 = gapi.auth2.init({client_id: '345920375777-l6mk18ljj9p416kj7u4qddebnt07trv6.apps.googleusercontent.com'});
      auth2.attachClickHandler((this.$refs.googleButton as any).$el, {}, (googleUser: any) => {
        this.loading = true
        this.http.globalPost<LoginResponse>(
            "auth/google",
            {idToken: googleUser.getAuthResponse().id_token},
            (json: LoginResponse) => {
              if (json.result) {
                vxm.app.setUserToken(json.token)
                this.$nextTick(() => {
                  this.$router.replace("/")
                })
              } else {
                vxm.app.sendSnack(new StoreSnack(json.message, "error", 4000))
              }
            },
            error => {
              if (error) {
                vxm.app.sendSnack(new StoreSnack("Google Sign-In failed!", "error", 4000))
              }
              this.$nextTick(() => {
                this.loading = false
              })
            }
        )
      }, (_: any) => {
        vxm.app.sendSnack(new StoreSnack("Google Sign-In failed!", "error", 4000))
        this.$nextTick(() => {
          this.loading = false
        })
      })
    })
  }

}
