




































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import {ContentTag} from "@/Models"
import Rules from "@/Rules"

@Component({
  components: {TableBasedView}
})
export default class TagsContent extends Vue {

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'name', width: '80%'},
    {text: 'Private', align: 'center', sortable: false, value: 'private', width: '20%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  defaultItem = new ContentTag()
  rules = new Rules()

}
