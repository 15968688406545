




























































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import Http from "@/Http"
import {Content, VData, SingleTemplate} from "@/Models"
import Rules from "@/Rules"
import {vxm} from "@/Store"

@Component({components: {TableBasedView}})
export default class SingleTemplates extends Vue {

  currentDate = new Date()
  currentTs = this.currentDate.getFullYear() * 10000 + (this.currentDate.getMonth() + 1) * 100 + this.currentDate.getDate()

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'custom2', width: '60%'},
    {text: 'Tags', align: 'start', sortable: false, value: 'custom1', width: '35%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  date1 = false
  date2 = false
  store = vxm.app
  defaultItem = new SingleTemplate()
  rules = new Rules()
  http = new Http()
  loading = true
  loadingError = false
  vdata: VData = new VData()

  isValid(item: Content): boolean {
    let to = parseInt(item.validityTo.replaceAll("-", ""))
    return (to > this.currentTs)
  }

  created() {
    this.loadData()
  }

  loadData() {
    this.loading = true
    this.loadingError = false
    this.http.apiGet<VData>(
        "data",
        (value) => {
          this.vdata = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

}
