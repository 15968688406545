

































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import {UtmCampaign} from "@/Models"
import Rules from "@/Rules"

@Component({
  components: {TableBasedView}
})
export default class UtmCampaigns extends Vue {

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'name', width: '100%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  defaultItem = new UtmCampaign()
  rules = new Rules()

}
