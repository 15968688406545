





























































































































































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator';
import Http from "@/Http";
import {Emit} from "vue-property-decorator";
import {Content} from "@/Models";

interface EditResponse {
  result: boolean,
  add: boolean,
  id: string
}

interface DeleteResponse {
  result: boolean
}


@Component
export default class TableBasedView extends Vue {

  @Prop({default: ''})
  title: string | undefined

  @Prop({default: ''})
  apiUrl: string | undefined

  @Prop({default: {}})
  defaultItem: any | undefined

  @Prop({default: []})
  headers: any[] | undefined

  @Prop({default: "500px"})
  maxWidth: string | undefined

  @Prop({default: false})
  showInfo: boolean | undefined

  @Prop({default: false})
  goBackEnabled: boolean | undefined

  @Prop({default: false})
  hideNewButton: boolean | undefined

  @Prop({default: ''})
  icon: string | undefined


  shownInfo = false
  search = ""
  http = new Http()
  itemsPerPage = 50
  loading = true
  loadingError = false
  dialog = false
  dialogDelete = false
  items: any[] = []
  editedItem: any = Object.assign({}, this.defaultItem)
  editedIndex = -1


  updateSearch(text: string) {
    this.search = text
  }

  customFilter(value: any, search: any, item: any) {
    if (item.search) {
      return item.search.includes(search.toLowerCase())
    } else {
      return JSON.stringify(item).toLowerCase().includes
    }
  }

  customSort(items: any[], sortBy: string[], sortDesc: boolean[], locale: string, customSorters?: Record<string, any>): any[] {
    if (sortBy.length > 0 && sortBy[0] == "id") {
      const desc = sortDesc.length > 0 && sortDesc[0]
      return items.sort((i1, i2) => {
        return (desc ? -1 : 1) * (i1.id - i2.id)
      })
    } else {
      const desc = sortDesc.length > 0 && sortDesc[0]
      return items.sort((i1, i2) => {
        return (desc ? -1 : 1) * i1.name.toLowerCase().localeCompare(i2.name.toLowerCase(), locale, {sensitivity: "base"})
      })
    }
  }

  get formTitle(): string {
    return this.editedIndex == -1 ? 'New Item' : 'Edit Item'
  }

  get showNewButton(): boolean {
    if (this.hideNewButton == undefined) {
      return !this.loading && !this.loadingError
    } else {
      return !this.loading && !this.loadingError && !this.hideNewButton
    }
  }

  @Watch("dialog")
  dialogWatcher(val: boolean) {
    if (val) {
      this.$emit("before-dialog", true)
    }
    val || this.close()
  }

  @Watch("dialogDelete")
  dialogDeleteWatcher(val: boolean) {
    val || this.closeDelete()
  }

  created() {
    this.loadData()
  }

  async loadData() {
    this.loading = true
    this.loadingError = false
    this.http.apiGet<any[]>(
        this.apiUrl as string,
        (value) => {
          this.items = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }


  editItem(item: any) {
    this.editedIndex = this.items.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialog = true
  }

  deleteItem(item: any) {
    this.editedIndex = this.items.indexOf(item)
    this.editedItem = Object.assign({}, item)
    this.dialogDelete = true
  }

  deleteItemConfirm() {
    this.http.apiDelete<DeleteResponse>(
        this.apiUrl + "/" + this.editedItem.id,
        (response) => {
          if (response.result) {
            this.items.splice(this.editedIndex, 1)
          }
        },
        (error) => {
          this.closeDelete()
        })
  }

  close() {
    this.dialog = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    })
  }

  closeDelete() {
    this.dialogDelete = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    })
  }

  closeReset(ref: any | undefined) {
    this.dialog = false
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
      if (ref != undefined) ref.resetValidation()
    })
  }

  saveAsNew(ref: any | undefined) {
    this.saveItemInternal(ref, true)
  }

  save(ref: any | undefined) {
    this.saveItemInternal(ref, false)
  }

  saveItemInternal(ref: any | undefined, resetId: boolean) {
    let canProceed = true
    if (ref != undefined && !ref.validate()) canProceed = false
    if (canProceed) {

      const sendData = Object.assign({}, this.editedItem)
      if (resetId) {
        sendData.id = -1
      }

      this.http.apiPost<EditResponse>(
          this.apiUrl as string,
          sendData,
          (response) => {
            if (response.result) {
              if (response.add) {
                this.editedItem.id = response.id
                this.items.push(this.editedItem)

                this.items.forEach((item, index) => {
                  if (item.id < 0 && item.name == this.editedItem.name) {
                    this.items.splice(index, 1)
                  }
                })

                this.close()
                this.$nextTick(() => {
                  if (ref != undefined) ref.resetValidation()
                })
              } else {
                Object.assign(this.items[this.editedIndex], this.editedItem)
                this.close()
                this.$nextTick(() => {
                  if (ref != undefined) ref.resetValidation()
                })
              }
            }
          },
          (error) => {
          })
    }
  }

}
