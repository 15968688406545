














































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import Http from "@/Http"
import {Content, VData, ResultResponse} from "@/Models"
import Rules from "@/Rules"
import {vxm} from "@/Store"

class VariantTag {
  id: number
  published: boolean = false
  name: String = ""

  constructor(id: number, published: boolean, name: string) {
    this.id = id
    this.published = published
    this.name = name
  }
}

@Component({components: {TableBasedView}})
export default class Contents extends Vue {

  batchDialog = false
  batchItem = new Content()
  batchLoading = false

  currentDate = new Date()
  currentTs = this.currentDate.getFullYear() * 10000 + (this.currentDate.getMonth() + 1) * 100 + this.currentDate.getDate()

  filterTags = true

  numberOfUrls(item: Content): number {
    let counter = 0
    item.url.split("\n").forEach(line => {
      if (line.trim().length > 0) {
        counter++
      }
    })
    return counter
  }

  private urlWeight(input: string): number {
    const index = input.indexOf(":")
    if (index === -1) return 0
    const value = parseInt(input.substring(index + 1, input.length))
    return value
  }


  filterColor(tag: string): string {
    if (tag.startsWith("#published")) return "green darken-3"
    if (tag.startsWith("#not-published")) return "green darken-3"
    if (tag.startsWith("#need-review")) return "pink darken-4"
    if (tag.startsWith("#not-need-review")) return "pink darken-4"
    if (tag.startsWith("#rejected")) return "indigo darken-3"
    if (tag.startsWith("#not-rejected")) return "indigo darken-3"
    if (tag.startsWith("#valid")) return "cyan darken-3"
    if (tag.startsWith("#not-valid")) return "cyan darken-3"
    if (tag.startsWith("#variants-ready")) return "blue-grey darken-2"
    if (tag.startsWith("#not-variants-ready")) return "blue-grey darken-2"

    if (tag.startsWith("#variants-published:")) return "orange darken-4"
    if (tag.startsWith("#variants-total:")) return "deep-orange darken-4"

    if (tag.startsWith("#placement:")) return "deep-purple darken-1"
    if (tag.startsWith("#not-placement:")) return "deep-purple darken-4"
    if (tag.startsWith("#campaign:")) return "brown darken-1"
    if (tag.startsWith("#content:")) return "blue darken-2"
    if (tag.startsWith("#variant:p")) return "green darken-1"
    if (tag.startsWith("#variant:n")) return "red darken-1"
    if (tag.startsWith("#source:")) return "orange darken-4"
    if (tag.startsWith("#not-source:")) return "deep-orange darken-4"
    if (tag.startsWith("#variants-ready")) return "green darken-4"
    if (tag.startsWith("#not-variants-ready")) return "green darken-4"
    if (tag.startsWith("#variants")) return "lime darken-4"
    return "green darken-4"
  }

  filterRank(tag: string): number {
    if (tag.startsWith("#published")) return 10
    if (tag.startsWith("#not-published")) return 20
    if (tag.startsWith("#need-review")) return 30
    if (tag.startsWith("#not-need-review")) return 40
    if (tag.startsWith("#rejected")) return 50
    if (tag.startsWith("#not-rejected")) return 60
    if (tag.startsWith("#valid")) return 70
    if (tag.startsWith("#not-valid")) return 80
    if (tag.startsWith("#variants-ready")) return 90
    if (tag.startsWith("#not-variants-ready")) return 100
    if (tag.startsWith("#placement:")) return 1000
    if (tag.startsWith("#not-placement:")) return 1100
    if (tag.startsWith("#campaign:")) return 4000
    if (tag.startsWith("#content:")) return 5000
    if (tag.startsWith("#variant:p")) return 2000
    if (tag.startsWith("#variant:n")) return 3000
    if (tag.startsWith("#source:")) return 6000
    if (tag.startsWith("#not-source:")) return 6100
    return 500
  }


  listFilter(items: Content[], applyFilter: boolean): string[] {

    let outputList: string[] = []

    items.forEach(item => {
      item.searchList.forEach(si => {
        if (outputList.find(value => value == si) == undefined) {
          if (!applyFilter || (applyFilter && this.filterRank(si) < 1000)) {
            outputList.push(si)
          }
        }
      })
    })

    outputList.sort((i1, i2) => {
      let r1 = this.filterRank(i1)
      let r2 = this.filterRank(i2)
      if (r1 < r2) return -1
      if (r1 > r2) return 1
      return i1.localeCompare(i2)
    })

    return outputList
  }


  hasVariants(item: Content): boolean {
    return !(item.variantInfo == undefined || item.variantInfo.length === 0)
  }

  getColor(tag: VariantTag) {
    return tag.published ? "green darken-1" : "red darken-1"
  }

  tagsFromVariant(item: Content): VariantTag[] {
    if (item.variantInfo == undefined) return []
    let counter = 0
    return item.variantInfo.map(line => {
      const type = line.substring(0, 1)
      const name = line.substring(2)
      return new VariantTag(
          counter++,
          type == "p",
          name
      )
    })
  }


  tagsFromUrl(item: Content): string[] {
    const count = this.numberOfUrls(item)
    const items = item.url.split("\n").filter(line => line.trim().startsWith("[")).map(line => {
      const input = line.trim().substring(1)
      const index = input.indexOf("]")
      if (index === -1) {
        return "INVALID"
      } else {
        return input.substring(0, index).replace(" ", "")
      }
    })
    if (count != 0 && items.length === 0) {
      return [count.toString() + " URL(s)"]
    } else {
      items.sort((i1, i2) => {
        if (this.urlWeight(i1) > this.urlWeight(i2)) return -1
        if (this.urlWeight(i1) < this.urlWeight(i2)) return 1
        if (i1.localeCompare(i2) > 0) return 1
        return -1
      })
      if (count > items.length) {
        items.push((count - items.length) + " URL(s)")
      }
      return items
    }
  }


  saveBatch(ref: any) {
    if (!ref.validate()) return
    this.batchLoading = true
    this.http.apiPost<ResultResponse>(
        "admin/batch-contents",
        this.batchItem,
        (response) => {
          if (response.result) {
            this.$nextTick(() => {
              if (ref != undefined) ref.resetValidation()
              this.batchItem = new Content()
              this.batchDialog = false
              this.loadData()
            })
          }
        },
        (error) => {
          this.batchLoading = false
        })
  }

  closeBatch(ref: any) {
    ref.resetValidation()
    this.batchDialog = false
  }

  headers = [
    {text: 'ID', align: 'start', sortable: true, value: 'id', width: '4%'},
    {text: 'Name', align: 'start', sortable: true, value: 'custom1', width: '40%'},
    {text: 'Tags', align: 'start', sortable: false, value: 'custom2', width: '40%'},
    // {text: 'Variants', align: 'left', sortable: false, value: 'custom3', width: '10%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  date1 = false
  date2 = false
  store = vxm.app
  defaultItem = new Content()
  rules = new Rules()
  http = new Http()
  loading = true
  loadingError = false
  vdata: VData = new VData()

  isValid(item: Content): boolean {
    let from = parseInt(item.validityFrom.replaceAll("-", ""))
    let to = parseInt(item.validityTo.replaceAll("-", ""))
    return (from < this.currentTs && to > this.currentTs)
  }

  created() {
    this.loadData()
  }

  loadData() {
    this.loading = true
    this.loadingError = false
    this.http.apiGet<VData>(
        "data",
        (value) => {
          this.vdata = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  openTemplate() {
    this.$router.push("/o/" + this.store.getOrgId + "/contents/0")
  }

  add1Y(validity: string): string {
    const parts: string[] = validity.split("-");
    return (parseInt(parts[0]) + 1) + "-" + parts[1] + "-" + parts[2];
  }

}
