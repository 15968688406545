










































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "@/components/TableBasedView.vue"
import Http from "@/Http"
import Rules from "@/Rules"
import {VData, Variant, Named, NamedImpl, ResultResponse, Content, BatchItem} from "@/Models"
import CopyVariants from "@/components/CopyVariants.vue";
import {vxm} from "@/Store"

@Component({
  components: {CopyVariants, TableBasedView}
})
export default class Variants extends Vue {

  previewDialog = false
  previewData: BatchItem | undefined = undefined

  template = -1
  copyDialog = false
  copyId = -1
  copyLoading = false

  closeCopy() {
    this.copyDialog = false
    this.copyId = -1
  }

  saveCopy() {
    if (this.copyId != -1) {
      this.copyLoading = true
      this.http.apiPost<ResultResponse>(
          "admin/copy-variants/" + this.$route.params.contentId,
          this.copyId,
          (response) => {
            if (response.result) {
              this.$nextTick(() => {
                this.loadData()
              })
            }
          },
          (error) => {
            this.copyDialog = false
            this.copyId = -1
            this.copyLoading = false
          })
    }
  }

  headers = [
    {text: 'Name', align: 'start', sortable: true, value: 'custom5', width: '30%'},
    {text: 'Configuration', align: 'start', sortable: false, value: 'custom1', width: '45%'},
    {text: 'Sources', align: 'start', sortable: false, value: 'sourceTags', width: '25%'},
    {text: 'Actions', align: 'center', value: 'actions', sortable: false}
  ]

  defaultItem = new Variant()
  rules = new Rules()
  http = new Http()
  loading = true
  store = vxm.app
  loadingError = false
  vdata: VData = new VData()

  groups: Named[] = []

  get apiUrl(): string {
    return "variants/" + this.$route.params.contentId
  }

  created() {
    for (let i = -1; i < 26; i++) {
      this.groups.push(new NamedImpl(i, i == -1 ? "None" : String.fromCharCode(65 + i)))
    }
    this.loadData()
  }

  loadData() {
    this.loading = true
    this.loadingError = false
    this.http.apiGet<VData>(
        "data",
        (value) => {
          this.vdata = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }

  preview(item: Variant) {
    this.http.apiGet<BatchItem>(
        "admin/preview-variant/" + item.id,
        (response) => {
          this.previewData = response
          this.previewDialog = true
        },
        (error) => {
        })
  }

  nonEmptyActionName(task: BatchItem) {
    if (task.actionName.length === 0) return "Perform action"
    return task.actionName
  }

  launchAction(task: BatchItem) {
    window.open(task.action, '_blank');
  }

  beforeDialog() {
    this.template = -1
  }

  useTemplate(editedItem: Variant) {
    if (this.template === -1) return
    const tpl = this.vdata.variantTemplates.find(item => item.id == this.template)
    if (tpl == undefined) return
    editedItem.name = tpl.name
    editedItem.utm = tpl.utm
    editedItem.post = tpl.post
    editedItem.groupId = tpl.groupId
    editedItem.instructionId = tpl.instructionId
    editedItem.utmUrlId = tpl.utmUrlId
    editedItem.weight = tpl.weight
    editedItem.sourceTags = tpl.sourceTags
    editedItem.hashTags = tpl.hashTags
  }

}
