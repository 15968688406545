


















import Component from "vue-class-component";
import Vue from "vue";
import {Content} from "@/Models";
import Http from "@/Http";
import {Prop, Watch} from "vue-property-decorator";

@Component
export default class CopyVariants extends Vue {

  @Prop({default: -1})
  value: number

  @Prop({default: -1})
  current: number

  loading = true
  loadingError = false
  http = new Http()
  contents: Content[] = []
  selection: number = -1

  @Watch("selection")
  selectionChanged(value: number, oldValue: number) {
    this.$emit("input", value)
  }

  @Watch("value")
  valueChange(value: number, oldValue: number) {
    this.selection = value
  }

  mounted() {
    this.loadData()
  }

  loadData() {
    this.loading = true
    this.loadingError = false
    setTimeout(() => {
      this.http.apiGet<Content[]>(
          "contents",
          (value) => {
            this.contents = value.sort((a, b) => {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            }).filter(v => {
              return v.id != this.current
            })
          },
          (wasError) => {
            this.finishLoading(wasError)
          }
      )
    }, 0)
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }

}

