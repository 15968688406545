






import Vue from 'vue'
import Component from 'vue-class-component'
import TableBasedView from "./TableBasedView.vue";
import {store} from "@/Store"

@Component({components: {TableBasedView}})
export default class Snackbar extends Vue {

  show = false
  message = ""
  color = ""
  timeout = 2000

  created() {
    store.subscribe((mutation, _) => {
      if (mutation.type == "app/sendSnack") {
        this.message = mutation.payload.message
        this.color = mutation.payload.color
        this.timeout = mutation.payload.timeout
        this.show = true
      }
    })
  }

}
