export class SourceTag {
    id: number = -1
    name: string = ""
    description: string = ""
    maxUser: string = "10"
    maxBatch: string = "1"
    maxTotal: string = "100"
    maxSource: string = "3"
    waitAll: string = "0"
    waitUser: string = "1"
    waitTag: string = "0"
    private: boolean = false
    singleMode: boolean = false
}

export class ContentTag {
    id: number = -1
    name: string = ""
    description: string = ""
    private: boolean = false
}

export class SingleTag {
    id: number = -1
    name: string = ""
    description: string = ""
    private: boolean = false
}

export class CheckTag {
    id: number = -1
    name: string = ""
    description: string = ""
    private: boolean = false
}

export class UtmUrl {
    id: number = -1
    name: string = ""
    value: string = ""
}

export class Placement {
    id: number = -1
    name: string = ""
    utm: string = ""
}

export class UtmMedium {
    id: number = -1
    name: string = ""
    utm: string = ""
}

export class UtmCampaign {
    id: number = -1
    name: string = ""
    utm: string = ""
}

export class HashTag {
    id: number = -1
    name: string = ""
    value: string = ""
}

export class Placeholder {
    id: number = -1
    name: string = ""
    value: string = ""
}

export class Instruction {
    id: number = -1
    name: string = ""
    pattern: string = ""
    post: string = ""
    action: string = ""
    actionName: String = ""
}

export class Source {
    id: number = -1
    name: string = ""
    utm: string = ""
    url: string = ""
    contentSpecific: boolean = true
    mediumId: number = -1
    contentTags: number[] = []
    validityFrom: string = new Date().toISOString().substr(0, 10)
    validityTo: string = new Date().toISOString().substr(0, 10)
}

export class Check {
    id: number = -1
    name: string = ""
    text: string = ""
    action: string = ""
    actionName: String = ""
    waitAll: string = "0"
    waitUser: string = "1"
    private: boolean = true
    active: boolean = true
    validityFrom: string = new Date().toISOString().substr(0, 10)
    validityTo: string = new Date().toISOString().substr(0, 10)
}

export class SingleTemplate {
    id: number = -1
    name: string = ""
    text: string = ""
    action: string = ""
    actionName: String = ""
    singleTags: number[] = []
}

export class SingleTask {
    id: number = -1
    name: string = "[" + new Date().toISOString().substr(0, 10) + "] "
    text: string = ""
    action: string = ""
    actionName: String = ""
    active: boolean = true
    validityTo: string = this.getValidityTo()
    singleTags: number[] = []

    getValidityTo(): string {
        const current = new Date().toISOString().substr(0, 10)
        const parts: string[] = current.split("-")
        let month = parseInt(parts[1]) + 1
        let year = parseInt(parts[0])
        let day = parseInt(parts[2])
        if (month > 12) {
            year++;
            month = 1;
        }
        if (day > 28) day = 28
        return (year.toString() + "-" + month.toString().padStart(2, "0") + "-" + day.toString().padStart(2, "0"))
    }

}

export class Content {
    id: number = -1
    name: string = ""
    utm: string = ""
    url: string = ""
    published: boolean = false
    needReview: boolean = false
    rejected: boolean = false
    campaignId: number = -1
    contentTags: number[] = []
    variantsPublished: number = 0
    variantsTotal: number = 0
    variantsReady: boolean = false
    validityFrom: string = new Date().toISOString().substr(0, 10)
    validityTo: string = new Date().toISOString().substr(0, 10)
    variantInfo: string[] | undefined = undefined
    search: string = ""
    searchList: string[] = []
}

export class User {
    id: number = -1
    name: string = ""
    role: number = 1
    priority: number = 3
    utm: string = ""
    contentBatch: number = 3
    singleBatch: number = 3
    sourceTags: number[] = []
    contentTags: number[] = []
}

export class Variant {
    id: number = -1
    name: string = ""
    utm: string = ""
    post: string = ""
    groupId: number = -1
    instructionId: number = -1
    utmUrlId: number = -1
    weight: number = 1
    sourceTags: number[] = []
    hashTags: number[] = []
}

export interface Named {
    id: number
    name: string
}

export class NamedImpl {
    id: number
    name: string

    constructor(id: number, name: string) {
        this.id = id
        this.name = name
    }
}

export class VData {
    variantTemplates: Variant[] = []
    singleTemplates: SingleTemplate[] = []
    contentTags: Named[] = []
    sourceTags: Named[] = []
    singleTags: Named[] = []
    checks: Named[] = []
    hashTags: Named[] = []
    utmUrls: Named[] = []
    utmCampaigns: Named[] = []
    utmMedia: Named[] = []
    instructions: Named[] = []
    placements: Named[] = []
}

export interface TagDataItem {
    id: number
    name: string
    description: String
    active: boolean
}

export class UserData {
    userBatch: number = 50
    contentTags: TagDataItem[] = []
    sourceTags: TagDataItem[] = []
    singleTags: TagDataItem[] = []
    checks: TagDataItem[] = []
}

export interface LoginResponse {
    result: boolean,
    message: string,
    token: string
}

export class Tokens {
    admin: string = ""
    member: string = ""
}

export class OrgList {
    list: OrgItem[] = []
    canCreate: boolean = false
}

export class OrgItem {
    id: number = -1
    name: string = ""
    data: string = ""
    role: number = -1
}

export class OrgResponse {
    name: string = ""
    role: number = -1
}

export class BatchData {
    generated: boolean = false
    tasks: BatchItem[] = []
}

export class BatchItem {
    id: number = -1
    name: string = ""
    type: string = ""
    instruction: string = ""
    accepted: boolean = false
    rejected: boolean = false
    action: string = ""
    actionName: string = ""
    post: string = ""
}

export class ResultResponse {
    result: boolean = false
}