























































































import Vue from 'vue'
import Component from 'vue-class-component'
import Rules from "@/Rules";
import {UserData, TagDataItem} from "@/Models";
import Http from "@/Http";
import {StoreSnack, vxm} from "@/Store";


@Component
export default class Profile extends Vue {

  rules = new Rules()
  http = new Http()
  loading = true
  loadingError = false
  data: UserData = new UserData()


  created() {
    this.loadData()
  }

  loadData() {
    this.loading = true
    this.loadingError = false
    this.http.apiGet<UserData>(
        "user/profile",
        (value) => {
          this.data = value
        },
        (wasError) => {
          this.finishLoading(wasError)
        }
    )
  }

  finishLoading(wasError: boolean) {
    this.$nextTick(() => {
      this.loading = false
      this.loadingError = wasError
    })
  }

  save() {
    let request = {
      contentTags: this.data.contentTags.map((item: TagDataItem) => {
        return {
          id: item.id,
          active: item.active
        }
      }),
      sourceTags: this.data.sourceTags.map((item: TagDataItem) => {
        return {
          id: item.id,
          active: item.active
        }
      }),
      singleTags: this.data.singleTags.map((item: TagDataItem) => {
        return {
          id: item.id,
          active: item.active
        }
      }),
      checkTags: this.data.checks.map((item: TagDataItem) => {
        return {
          id: item.id,
          active: item.active
        }
      })
    }

    this.http.apiPost<UserData>(
        "user/profile",
        request,
        (value) => {
          vxm.app.sendSnack(new StoreSnack("Saved.", "success"))
        },
        (wasError) => {
        }
    )
  }

}
